.rc-select {
  display: inline-block;
  font-size: 12px;
  width: 100px;
  position: relative;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}
.rc-select-disabled,
.rc-select-disabled input {
  cursor: not-allowed;
}
.rc-select-disabled .rc-select-selector {
  opacity: 0.3;
}
.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::after {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 2px solid #999;
  border-top-color: transparent;
  border-bottom-color: transparent;
  transform: none;
  margin-top: 4px;
  animation: rcSelectLoadingIcon 0.5s infinite;
}
.rc-select .rc-select-selection-placeholder {
  opacity: 0.4;
}
.rc-select-single .rc-select-selector {
  display: flex;
  position: relative;
}
.rc-select-single .rc-select-selector .rc-select-selection-search {
  width: 100%;
}
.rc-select-single .rc-select-selector .rc-select-selection-search-input {
  width: 100%;
}
.rc-select-single .rc-select-selector .rc-select-selection-item,
.rc-select-single .rc-select-selector .rc-select-selection-placeholder {
  position: absolute;
  top: 1px;
  left: 3px;
  pointer-events: none;
}
.rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
  padding: 1px;
  border: 1px solid #000;
}
.rc-select-single:not(.rc-select-customize-input)
  .rc-select-selector
  .rc-select-selection-search-input {
  border: none;
  outline: none;
  background: rgba(255, 0, 0, 0.2);
  width: 100%;
}
.rc-select-multiple .rc-select-selector {
  display: flex;
  flex-wrap: wrap;
  padding: 1px;
  border: 1px solid #000;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item {
  flex: none;
  background: #bbb;
  border-radius: 4px;
  margin-right: 2px;
  padding: 0 8px;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-item-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search {
  position: relative;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input,
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
  padding: 1px;
  font-family: system-ui;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
  position: absolute;
  z-index: 999;
  white-space: nowrap;
  position: none;
  left: 0;
  top: 0;
  visibility: hidden;
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input {
  border: none;
  outline: none;
  background: rgba(255, 0, 0, 0.2);
  width: 100%;
}
.rc-select-allow-clear.rc-select-multiple .rc-select-selector {
  padding-right: 20px;
}
.rc-select-allow-clear .rc-select-clear {
  position: absolute;
  right: 20px;
  top: 0;
}
.rc-select-show-arrow.rc-select-multiple .rc-select-selector {
  padding-right: 20px;
}
.rc-select-show-arrow .rc-select-arrow {
  pointer-events: none;
  position: absolute;
  right: 5px;
  top: 0;
}
.rc-select-show-arrow .rc-select-arrow-icon::after {
  content: '';
  border: 5px solid transparent;
  width: 0;
  height: 0;
  display: inline-block;
  border-top-color: #999;
  transform: translateY(5px);
}
.rc-select-focused .rc-select-selector {
  border-color: blue !important;
}
.rc-select-dropdown {
  border: 1px solid green;
  min-height: 100px;
  position: absolute;
  background: #fff;
  color: black;
}
.rc-select-dropdown-hidden {
  display: none;
}
.rc-select-item {
  font-size: 16px;
  line-height: 1.5;
  padding: 4px 16px;
}
.rc-select-item-group {
  color: #999;
  font-weight: bold;
  font-size: 80%;
}
.rc-select-item-option {
  position: relative;
}
.rc-select-item-option-grouped {
  padding-left: 24px;
}
.rc-select-item-option .rc-select-item-option-state {
  position: absolute;
  right: 0;
  top: 4px;
  pointer-events: none;
}
.rc-select-item-option-active {
  background: green;
}
.rc-select-item-option-disabled {
  color: #999;
}
.rc-select-item-empty {
  text-align: center;
  color: #999;
}
.rc-select-selection__choice-zoom {
  transition: all 0.3s;
}
.rc-select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);
}
.rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
  opacity: 1;
  transform: scale(1);
}
.rc-select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);
}
.rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
  opacity: 0;
  transform: scale(0.5);
}
.rc-select-dropdown-slide-up-enter,
.rc-select-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  // animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: ease;
  animation-play-state: paused;
}
.rc-select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  // animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: ease;
  animation-play-state: paused;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpOut;
  animation-play-state: running;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}
.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownOut;
  animation-play-state: running;
}
@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}






.rc-select {
  font-size: inherit;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  width: 100%;
  span[aria-live='polite'] {
    // Use Bootstrap's screen reader only styles for rc-select's aria-live hint.
    // Without this, our FormGroup component's label becomes misaligned when a
    // sibling rc-select element is focused because the text baseline shifts.
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
  &.rc-select div.rc-select-selector {
    border-radius: 4px;
    padding: 0;
  }
  .rc-select-selection-search-input {
    height: calc(1.5em + 0.75rem);
    cursor: default;
  }
  &.rc-select-single:not(.rc-select-customize-input)
    .rc-select-selector
    .rc-select-selection-search-input {
    background: none;
    padding: 0.375rem 0.75rem;
  }
  .rc-select-arrow {
    font-size: 14px;
    // @include themed('filter', 'input', 'select', 'svg');
    color: #d8d8d8;
    svg {
      fill: currentColor;
    }
  }
  &:hover .rc-select-arrow,
  &.rc-select-focused .rc-select-arrow {
    // @include themed('filter', 'input', 'select', 'svg', 'hover');
    color: #999;
  }
  &.rc-select-open .rc-select-arrow {
    // @include themed('filter', 'input', 'select', 'svg', 'active');
    color: #333;
  }
  &.rc-select-single .rc-select-selector .rc-select-selection-item,
  .rc-select-selector .rc-select-selection-placeholder {
    position: absolute;
    top: 4px;
    left: 14px;
    right: 42px;
    bottom: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
    }
  }
  // Styles for multiple item select
  &.rc-select-multiple .rc-select-selector {
    // Make space for the pencil to appear
    padding-right: 2em;
    .rc-select-selection-item {
      height: calc(1.5em + 0.75rem);
      line-height: calc(1.5em + 0.75rem);
      margin: 2px;
      background: rgba(0, 0, 0, 0.1);
      display: inline-block;
    }
    .rc-select-selection-item-remove {
      padding: 0.4em 12px;
      margin-right: -8px;
    }
    .rc-select-selection-search {
      margin-top: 2px;
      margin-left: 3px;
      .rc-select-selection-search-input {
        background: none;
      }
    }
  }
  .rc-select-arrow {
    top: 4px;
    right: 14px;
    bottom: 4px;
    display: flex;
    align-items: center;
  }
  &.HasError {
    .rc-select-selector {
      box-shadow: 0 0 0 0.2rem rgba(255, 127, 127, 0.5);
    }
  }
  // Prevent faded appearance when this select is disabled, instead hiding the
  // pencil and remove item icons
  &.rc-select-disabled {
    .rc-select-selector {
      opacity: 1;
      cursor: default;
      .rc-select-selection-item-remove {
        display: none;
      }
    }
  }
}

.SelectDropdown {
  border: 0;
  min-height: 0;
  padding: 4px;
  background: none;
  z-index: 103;
  > div {
    padding: 4px 0;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    border-radius: 4px;
    // @include themed('filter', 'input', 'select');
    background-color: #f2f5f8;
    border-color: #979797;
    color: #adb2b7;
  }
  .rc-select-item {
    line-height: normal;
    cursor: default;
  }
  .rc-select-item-option {
    // @include themed('filter', 'input', 'text');
    color: #36454e;
  }
  .rc-select-item-option-selected {
    // @include themed('filter', 'input', 'select', 'active');
    background-color: #fefefe;
  }
  .rc-select-item-option-active {
    // @include themed('filter', 'input', 'select', 'hover');
    background-color: #ebeef1;
  }
}
