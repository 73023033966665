$link-color: #2c4461;

.Header {
  position: relative;
  font-size: 20px;

  // Layout
  padding: 0 0 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Colors
  background: #3e5674;
  background: linear-gradient(135deg, #3e5674, #2c4461);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
  :global(.svg-inline--fa) {
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .3));
  }

  nav > h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
}

.Logo {
  font-size: 48px;
  display: inline-block;
  vertical-align: middle;
}

.PageName {
  margin: 0 16px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-top: -6px;
}

.Search {
  flex-grow: 1;
  padding: 0 20px;
  height: 100%;
  :global(.rc-select) {
    height: 100%;
    font-size: 24px;
    :global(div.rc-select-selector) {
      height: 100%;
      border-radius: 0;
      border: 0;
    }
  }
  :global(.rc-select-selection-search-input) {
    color: white;
    height: 100%;
    font-size: 1em;
  }
  :global(.rc-select-arrow) {
    display: none;
  }
}

.Buttons button {
  border: 0;
  margin: 0;
  padding: 16px 20px;
  background: none;
  color: inherit;
  font-size: 30px;
  border-left: 1px solid rgba(255, 255, 255, .2);
  cursor: pointer;
  &[disabled] {
    opacity: .4;
  }
  &:hover, &:focus {
    background: rgba(255, 255, 255, .2);
  }
}

.Menu {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 100%;
  padding: 10px;
  transform: translateY(-15px);
  height: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  transition: opacity .3s ease, transform .3s ease;
  + button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    position: fixed;
  }
  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > ul {
    border: 1px solid $link-color;
    border-radius: 4px;
    min-width: 240px;
    position: relative;
    z-index: 2;
  }
  a, button {
    position: relative;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 8px 12px;
    border: 0;
    font-size: 1em;
    font-family: inherit;
    font-weight: inherit;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
    text-align: left;
    box-sizing: border-box;
    color: $link-color;
    border-bottom: 1px solid $link-color;
    background: white;
    &:hover {
      background: #3e5674;
      color: white;
    }
  }
  li:first-child a {
    border-radius: 3px 3px 0 0;
    &:before {
      content: '';
      position: absolute;
      right: 16px;
      top: -7px;
      border: 1px solid $link-color;
      border-width: 1px 0 0 1px;
      transform: rotate(45deg);
      width: 11px;
      height: 11px;
      background: inherit;
    }
  }
  li:last-child a {
    border-radius: 0 0 3px 3px;
    border-bottom: 0;
  }
  &.Open {
    padding: 10px;
    opacity: 1;
    height: auto;
    transform: translateY(0px);
    + button {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }
  }
}
