.Card {
}
.ImageWrapper {
  // max-width: 240px;
  margin: 0 auto 20px;
}
.Image {
  padding-bottom: 139.4%;
  position: relative;
  height: 0;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
.CardOption > div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  white-space: nowrap;
  .Name {
    flex-shrink: 2;
    overflow: hidden;
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 1px;
  }
}
