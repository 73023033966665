.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Main {
  flex-grow: 1;
  background: linear-gradient(90deg , #ccc, #fff 4px);
  background-position: 4px 0;
  overflow: scroll;
}

@for $i from 1 through 16 {
  .Columns-#{$i} {
    background-size: calc((100vw - 10px) / #{$i});
  }
}
